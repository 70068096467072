import { render, staticRenderFns } from "./PortfolioGrid.vue?vue&type=template&id=1bc9b16a&scoped=true&"
import script from "./PortfolioGrid.vue?vue&type=script&lang=js&"
export * from "./PortfolioGrid.vue?vue&type=script&lang=js&"
import style0 from "./PortfolioGrid.vue?vue&type=style&index=0&id=1bc9b16a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc9b16a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PortfolioGridItem: require('/vercel/path0/frontend/components/portfolio/PortfolioGridItem.vue').default,OutlineGridItem: require('/vercel/path0/frontend/components/ui/OutlineGridItem.vue').default,Button: require('/vercel/path0/frontend/components/ui/Button.vue').default})
