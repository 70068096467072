
export default {
  props: {
    investments: {
      type: Array,
      required: true,
    },
    showMoreProjects: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    total() {
      return this.showMoreProjects ? this.investments.length + 1 : this.investments.length;
    },
  },
};
