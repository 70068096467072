
export default {
  props: {
    investment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },
};
