
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    lg: {
      type: Number,
      default: 4,
    },
    md: {
      type: Number,
      default: 3,
    },
    sm: {
      type: Number,
      default: 2,
    },
    base: {
      type: Number,
      default: 2,
    },
  },

  computed: {
    columns() {
      if (this.$screen.lg) {
        return this.lg;
      }

      if (this.$screen.md) {
        return this.md;
      }

      if (this.$screen.sm) {
        return this.sm;
      }

      return this.base;
    },
    isLastInRow() {
      return (this.index % this.columns === 0 || this.index === this.total) && this.total >= this.columns;
    },
    isLastInColumn() {
      const row = Math.ceil(this.index / this.columns);
      const lastRow = Math.ceil(this.total / this.columns);

      return row === lastRow || !(this.total > this.columns);
    },
  },

};
