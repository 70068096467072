
export default {
  data() {
    return {
      investments: [],
      investmentsTotal: 0,
      currentType: 'project',
      types: [],
    };
  },

  async fetch() {
    try {
      this.types = await this.$axios.$get('/public/investments/types');
      this.categories = [
        {
          id: 'all',
          title: 'All',
        },
        ...await this.$axios.$get('/public/investments/categories'),
      ];
    } catch (error) {
      console.log(error);
    }

    await this.loadInvestments();
  },

  watch: {
    currentType() {
      this.loadInvestments();
    },
  },

  methods: {
    async loadInvestments() {
      try {
        const investments = await this.$axios.$get('/public/investments', {
          params: {
            type: this.currentType,
            limit: 11,
          },
        });

        this.investments = investments.results;
        this.investmentsTotal = investments.total;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
