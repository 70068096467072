
export default {
  props: {
    current: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
